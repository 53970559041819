import React, { Component } from 'react'
import Layout from 'components/layout'
import Title from 'components/title'
import { graphql } from 'gatsby'

const Job = ({ job, accordionToggle, openId }) => {
  const openClass = openId === job.id ? 'open' : ''
  return (
    <div className={`item accordion-holder ${openClass}`}>
      <div
        className="trigger accordion-trigger has-arrow"
        onClick={accordionToggle}
        data-id={job.id}
      >
        {job.title}
        <div className="arrow-right-ac" />
      </div>
      <div className="details accordion-hide">
        {job.content && (
          <div
            className="inner"
            dangerouslySetInnerHTML={{
              __html: job.content.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
    </div>
  )
}

class Careers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openId: '',
    }
    this.accordionToggle = this.accordionToggle.bind(this)
  }

  accordionToggle(e) {
    const getId = e.currentTarget.getAttribute('data-id')
    this.setState((prevState) => {
      if (prevState.openId === getId) {
        return { openId: '' }
      } else {
        return { openId: getId }
      }
    })
  }

  render() {
    const data = this.props.data
    const locale = data.site.siteMetadata.locale
    // const translations = data.translations;
    // const tr = (code) => trFunction(code, translations, locale);
    const page = data.thisPage
    const jobs = data.allJobs.edges

    return (
      <Layout>
        <div id="content">
          <Title title={page.title} />
          <section className="page-content wrap">
            {page.body && (
              <div
                className="wrap-text-large centered"
                dangerouslySetInnerHTML={{
                  __html: page.body.childMarkdownRemark.html,
                }}
              />
            )}
            <div className="dealers-list">
              <div className="layout-full">
                <div className="col">
                  <div className="module_accordion module-dealers">
                    <div className="items">
                      {jobs.map((job, i) => (
                        <Job
                          key={i}
                          job={job.node}
                          accordionToggle={this.accordionToggle}
                          openId={this.state.openId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default Careers

export const careersPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "careers" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        id
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        imagesFullMobile {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        imagesFullLink
        buttonLink
        imageSingle {
          file {
            url
          }
        }
        video {
          wistiaCode
        }
        slides {
          images {
            file {
              url
            }
          }
          caption
        }
      }
    }
    allJobs: allContentfulJobs(sort: { fields: [datePosted], order: DESC }) {
      edges {
        node {
          id
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
